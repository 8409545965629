<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card>
                  <v-card-title class="h6">
                    Comprobante de facturación OS
                  </v-card-title>
                  <v-card-text>
                    <v-row class="mb-n4">
                      <!-- Agente de facturacion -->
                      <v-col cols="3" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Agente de facturación"
                          v-model="agenteFacturacion"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- CUIT -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="CUIT"
                          v-model="cuit"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Direccion -->
                      <v-col cols="3" md="6" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Dirección"
                          v-model="direccionAgente"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n4">
                      <!-- Empresa -->
                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Entidad facturante"
                          v-model="empresa"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Obra social -->
                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Convenio"
                          v-model="obraSocial"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Subconvenio -->
                      <v-col
                        cols="12"
                        md="2"
                        class="py-0"
                        v-if="this.paramGralSubConvenio"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Subconvenio"
                          v-model="subConvenio"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Comp cta y orden terceros -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Cta y O. Terc."
                          v-model="terceros"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card>
                  <v-card-title class="h6 mt-n2"> Comprobante </v-card-title>
                  <v-card-text>
                    <v-row class="mb-n4">
                      <!-- Tipo de comprobante -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Tipo de comprobante"
                          v-model="tipoComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- numero de comprobante -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Número de comprobante"
                          v-model="numeroComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- periodo -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Período"
                          v-model="periodo"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- fecha -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha"
                          v-model="fechaComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- fecha de vencimiento -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha de vencimiento"
                          v-model="fechaVencimiento"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n4">
                      <!-- neto -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Neto"
                          v-model="neto"
                          autocomplete="off"
                          class="right-input"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- iva -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="IVA"
                          v-model="iva"
                          autocomplete="off"
                          class="right-input"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- % percepcion IIBB -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Porc. Percepción IIBB"
                          v-model="porcPercepIIBB"
                          autocomplete="off"
                          class="right-input"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- percepcion IIBB -->
                      <v-col cols="12" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Percepción IIBB"
                          v-model="percepcionIIBB"
                          class="right-input"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Total -->
                      <v-col cols="12" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Total"
                          v-model="total"
                          class="right-input"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <!-- Moneda del comprobante -->
                      <v-col
                        cols="12"
                        md="3"
                        class="py-0"
                        v-if="this.paramGralMultimoneda"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Moneda del comprobante"
                          v-model="monedaComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Fecha de cotizacion -->
                      <v-col
                        cols="12"
                        md="2"
                        v-if="this.paramGralMultimoneda"
                        class="py-0"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha cotización"
                          v-model="fechaCotizacion"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Moneda de consulta -->
                      <v-col
                        cols="12"
                        md="3"
                        class="py-0"
                        v-if="this.paramGralMultimoneda"
                      >
                        <v-select
                          v-model="monedaConsultaSelected"
                          :items="monedasConsultaItems"
                          item-text="value"
                          item-value="id"
                          return-object
                          label="Moneda de consulta"
                          @change="setComprobanteByMoneda()"
                          outlined
                          dense
                        >
                        </v-select>
                        <p class="invalidConfigText mt-n5" v-if="this.haveCotiza">
                          Sin cotización
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <!-- Texto o detalle de percepciones -->
                  <!-- Por ahora no tienen un @click porque lo de percepciones no se implementa -->
                  <!-- Solo se muestra texto1, texto2, texto3 => corresponde a btn texto -->
                  <v-row>
                    <v-col col="12" class="pt-0 pb-0">
                      <v-btn-toggle dense v-model="text" color="primary" group>
                        <v-btn value="Texto"> Texto del comprobante </v-btn>
                        <v-btn value="DetallePercep" disabled>
                          Detalle de percepciones
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- TEXTO 1 -->
                    <v-col cols="12" md="12" class="py-0 mb-n5">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        v-model="texto1"
                        autocomplete="off"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <!-- TEXTO 2 -->
                    <v-col cols="12" md="12" class="py-0 mb-n5">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        v-model="texto2"
                        autocomplete="off"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <!-- TEXTO 3 -->
                    <v-col cols="12" md="12" class="py-0 mb-n3">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        v-model="texto3"
                        autocomplete="off"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- tabla de conceptos del comprobante -->
                  <v-row>
                    <v-col
                      cols="12"
                      class="pt-0 mt-n2"
                      v-if="this.itemsComprobantes.length !== 0"
                    >
                      <v-data-table
                        :headers="headersComprobantes"
                        :items="itemsComprobantes"
                        height="100%"
                        fixed-header
                        class="elevation-1"
                        :loading="isLoadingTable"
                        hide-default-footer
                      >
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <!-- datos del CAE -->
                  <v-row class="ml-1" v-if="this.haveCAE">
                    <v-col cols="4" class="d-flex">
                      <p class="label">Número de CAE:</p>
                      <strong class="ml-2">{{ numeroCAE }}</strong>
                    </v-col>
                    <v-col cols="6" class="d-flex">
                      <p class="label">Fecha de vencimiento CAE:</p>
                      <strong class="ml-2 mb-0">{{ fechaVencCAE }}</strong>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import { mapActions } from "vuex";
export default {
  name: "DetalleComprobanteFacturacionOS",
  components: {
    GoBackBtn,
  },
  data() {
    return {
      routeToGo: this.$route.params.goBackName,
      agenteFacturacion: null,
      cuit: null,
      direccionAgente: null,
      empresa: null,
      obraSocial: null,
      subConvenio: null,
      terceros: null,
      tipoComprobante: null,
      numeroComprobante: null,
      fechaComprobante: null,
      periodo: null,
      fechaVencimiento: null,
      iva: null,
      neto: null,
      porcPercepIIBB: null,
      percepcionIIBB: null,
      total: null,
      monedaComprobante: null,
      monedaConsultaSelected: null,
      monedasConsultaItems: [],
      fechaCotizacion: null,
      numeroCAE: null,
      fechaVencCAE: null,
      texto1: null,
      texto2: null,
      texto3: null,
      isLoadingTable: false,
      paramGralMultimoneda: false,
      paramGralSubConvenio: false,
      haveCotiza: false,
      haveCAE: false,
      text: "Texto",
      itemsComprobantes: [],
      headersComprobantes: [
        {
          text: "Concepto",
          sortable: false,
          value: "concepto",
        },
        {
          text: "Cantidad",
          sortable: false,
          value: "pfCompiCant",
          align: "end",
        },
        {
          text: "Precio unitario",
          sortable: false,
          value: "pfCompiUnit",
          align: "end",
        },
        {
          text: "Neto",
          sortable: false,
          value: "pfCompiNeto",
          align: "end",
        },
        {
          text: "Porcentaje de IVA",
          sortable: false,
          value: "ivaPorc",
          align: "end",
        },
        {
          text: "IVA",
          sortable: false,
          value: "pfCompiIva",
          align: "end",
        },
        {
          text: "Total",
          sortable: false,
          value: "pfCompiTotal",
          align: "end",
        },
      ],
    };
  },
  created() {
    this.setParams();
    this.setDetalleComprobante();
  },
  methods: {
    ...mapActions({
      getComprobanteFacturacionOSById:
        "facturacionOS/getComprobanteFacturacionOSById",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getParametrosGralesBenef: "afiliaciones/getParametrosGralesBenef",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
    }),
    async setParams() {
      const responseParamSubConv = await this.getParametrosGralesBenef();
      this.paramGralSubConvenio = responseParamSubConv.subConvenio;
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
    },
    async setDetalleComprobante() {
      const dataMonedas = await this.getMonedasTaxonomy("habListado");
      this.monedasConsultaItems = dataMonedas;
      this.isLoadingTable = true;
      const data = {
        pfCompId: this.$route.params.pfCompId,
        monedaId: this.$route.params.monedaToDetalle
          ? this.$route.params.monedaToDetalle.id
          : -1,
        fechaCotizacion: this.$route.params.fechaCotizaToDetalle
          ? this.$route.params.fechaCotizaToDetalle
          : null,
      };
      this.fechaCotizacion = this.formatDate(
        this.$route.params.fechaCotizaToDetalle
      );
      const response = await this.getComprobanteFacturacionOSById(data);
      this.itemsComprobantes = response.conceptosItems;
      if (response.nroCAE != "") {
        this.haveCAE = true;
        this.numeroCAE = response.nroCAE;
        this.fechaVencCAE = response.fecVenCAE;
      }
      if(response.haveCotiza == 0) this.haveCotiza = true;
      this.agenteFacturacion = response.ageFactNom;
      this.cuit = response.cuit;
      this.direccionAgente = response.direccion;
      this.empresa = response.entFacturaNom;
      this.obraSocial = response.os;
      this.subConvenio = response.subConvNombre;
      this.terceros = response.tercerosNom;
      this.tipoComprobante = response.tipoCompNom;
      this.numeroComprobante = response.nroComp;
      this.fechaComprobante = response.fecha;
      this.periodo = response.periodo;
      this.fechaVencimiento = response.fechaVto;
      this.texto1 = response.texto1;
      this.texto2 = response.texto2;
      this.texto3 = response.texto3;
      this.neto = response.neto;
      this.iva = response.iva;
      this.porcPercepIIBB = response.alicPercIb;
      this.percepcionIIBB = response.percIb;
      this.total = response.total;
      this.monedaComprobante = response.monedaNombre;
      this.monedaConsultaSelected = this.$route.params.monedaToDetalle;
      this.isLoadingTable = false;
    },
    async setComprobanteByMoneda() {
      this.isLoadingTable = true;
      this.haveCotiza = false;
      const data = {
        pfCompId: this.$route.params.pfCompId,
        monedaId: this.monedaConsultaSelected
          ? this.monedaConsultaSelected.id
          : -1,
        fechaCotizacion: this.$route.params.fechaCotizaToDetalle
          ? this.$route.params.fechaCotizaToDetalle
          : null,
      };
      const response = await this.getComprobanteFacturacionOSById(data);
      if(response.haveCotiza == 0) this.haveCotiza = true;
      this.itemsComprobantes = response.conceptosItems;
      this.iva = response.iva;
      this.neto = response.neto;
      this.porcPercepIIBB = response.alicPercIb;
      this.percepcionIIBB = response.percIb;
      this.total = response.total;
      this.isLoadingTable = false;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
.blue-text {
  color: blue;
  text-decoration: underline blue;
}
.btnDisabled {
  color: #100f0f99;
}
.label {
  opacity: 0.7;
}
.invalidConfigText {
  color: red;
}
</style>